import './stylesheets/modal/modal.css';
import './stylesheets/modal/modalMobile.css';
import './stylesheets/modal/modalTablet.css';

import './stylesheets/resultsCard/resultsCard.css';
import './stylesheets/resultsCard/resultsCardMobile.css';

import React, {useEffect} from "react";

const Modal = (props) => {
  let name = props.name;
  let content = props.content;
  let open = props.open;
  let setOpen = props.setOpen;

  let modal = document.getElementById(name);

  window.onclick = function (event) {
    if (event.target === modal) {
      setOpen(!open);
    }
  }

  useEffect(() => {
    if (modal !== null) {
      if (open) {
        modal.style.display = 'block';
      } else {
        modal.style.display = 'none';
      }
    }
  }, [open, modal])

  return (
    <div id={name} className="modal text">
      {content}
    </div>
  )
}

export default Modal;