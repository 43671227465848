import {getCookie} from "./cookies";
import {createResultsShareText} from "./createShare";
import {isMobile} from "../Game";
import {setResults} from "./storeResults";

export function styleShareModal(setResultsOpen, mode)  {
    let shareText = document.getElementById("share_text");
    let shareBtn = document.getElementById('share_button');

    if (mode === "2024" && getCookie("2024recapcompleted") === "true") {
        if (isMobile) {
            shareText.style.fontSize = '18px';
        } else {
            shareText.style.fontSize = '25px';
        }
        shareText.innerHTML = `Congratulations, you completed the ${"Albumdle 2024 Recap!".bold()} Thank you for playing, if you would like to play again, you can restart below. <i>Your score will not reset.</i>`;

        shareBtn.style.display = 'inline-block'

        // shareButton(mode);
    } else if (getCookie(`${mode}completed`) === 'true') {
        shareText.innerHTML = createResultsShareText(false, mode);
        if (isMobile) {
            shareText.style.fontSize = '11vw';
        } else {
            shareText.style.fontSize = '4em';
        }

        shareBtn.style.display = 'inline-block'

        // shareButton(mode);
    } else {
        if (isMobile) {
            shareText.style.fontSize = '4vw';
        } else {
            shareText.style.fontSize = '2vw';
        }

        shareBtn.style.display = 'none'
        shareText.innerHTML = "<b>Albumdle</b> not yet completed.";
    }

    setResultsOpen(true);
    setTimeout(() => {
        setResults(null, mode);
    }, 150)

}

// When the user clicks anywhere outside the modal, close it
window.onclick = function(event) {
    var modal = document.getElementById("resultsModal");
    if (event.target === modal) {
        // closeModal(modal);
    }
}

export async function shareResults(mode) {
    var today = new Date();
    var date = ('0' + today.getDate()).slice(-2) + '/' + ('0' + (today.getMonth() + 1)).slice(-2) + '/' + today.getFullYear();

    var amountOfTiles = 0;
    if (getCookie(`${mode}tiles`) !== "") {
        amountOfTiles = (16 - parseInt(JSON.parse(getCookie(`${mode}tiles`)).length));
    }


    let shareData = "";

    if (mode === "") {
        shareData = {
            title: 'Albumdle',
            text: `#Albumdle (${date})\n\n${createResultsShareText(true, mode)}\n\n${amountOfTiles.toString()}/16\nhttps://albumdle.com/`,
        }
    } else if (mode === "endless") {
        shareData = {
            title: 'Albumdle',
            text: `I've played #AlbumdleEndless ${getCookie(`endlessrandomAlbumListNo`)} times\n\n${createResultsShareText(true, mode)}\n\n${amountOfTiles.toString()}/16\nhttps://albumdle.com/`,
        }
    } else if (mode === "2024") {
        if (getCookie("2024recapcompleted") === "true") {
            shareData = {
                title: 'Albumdle',
                text: `I've completed #AlbumdleRecap2024\nhttps://albumdle.com/`,
            }
        } else {
            shareData = {
                title: 'Albumdle',
                text: `I've played #AlbumdleRecap2024 ${getCookie("2024randomAlbumListNo")} times\n\n${createResultsShareText(true, mode)}\n\n${amountOfTiles.toString()}/16\nhttps://albumdle.com/`,
            }
        }
    }

    const resultPara = document.querySelector('#error_msg');

    try {
        await navigator.share(shareData)
        resultPara.textContent = 'Results shared successfully'
    } catch (err) {
        // resultPara.textContent = `Error: ${err}`
        await navigator.clipboard.writeText(shareData.text);
        resultPara.textContent = `Results copied to clipboard`
    }
}