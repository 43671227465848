export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function createCookie(name, value, expiry) {
    if (expiry === "forever") {
        expiry = "Tue, 19 Jan 2038 03:14:07 UTC";
    }

    document.cookie = `${name}=${value}; expires=${expiry};`;
}

export function deleteCookie(name) {
    document.cookie = `${name}=""; Max-Age=0`
}