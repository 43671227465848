// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {collection, getDocs, getFirestore, query, where, limit, getCountFromServer} from "firebase/firestore";
import {getDownloadURL, getStorage, ref} from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAsnVRZZFG3NAwan_TVJBvbEJWk5O0_I2E",
    authDomain: "albumdle-fox.firebaseapp.com",
    projectId: "albumdle-fox",
    storageBucket: "albumdle-fox.appspot.com",
    messagingSenderId: "101519543882",
    appId: "1:101519543882:web:ed1d623887986eef48e0b3",
    measurementId: "G-ZBHCEPNSDE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);

var album = [];

const q = query(collection(db, "albums"), where("today", "==", true));

const querySnapshot = await getDocs(q);
querySnapshot.forEach((doc) => {
    let albumData = doc.data();

    album.push(albumData.album);
    album.push(albumData.artist);
});



export function getAlbum() {
    return album;
}

export function getAlbumList(setArrayOfAlbums) {
    let albumList = localStorage.getItem('new-albumList');
    let albumsTimestamp = localStorage.getItem('new-albumsTimestamp');

    // Calculate midnight of the current day
    const today = new Date();
    const midnight = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);

    if (!albumList || !albumsTimestamp || new Date(albumsTimestamp).getTime() !== midnight.getTime()) {
        const pathReference = ref(storage, 'albums/albums.csv');
        getDownloadURL(pathReference).then((url) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'text';
            xhr.onload = (event) => {
                albumList = xhr.response.split(/\r?\n/);
                localStorage.setItem('new-albumList', JSON.stringify(albumList));
                localStorage.setItem('new-albumsTimestamp', midnight); // Store current timestamp
                setArrayOfAlbums(albumList);
            };
            xhr.open('GET', url);
            xhr.send();
        });
    } else {
        albumList = JSON.parse(albumList);
        setArrayOfAlbums(albumList);
    }
}

export async function getAlbumCollection(collectionName, mode) {
    // Try to get pastAlbums and its timestamp from localStorage
    let pastAlbums = localStorage.getItem(collectionName);
    let albumsTimestamp = localStorage.getItem(`${mode}albumsTimestamp`);

    if (!pastAlbums || !albumsTimestamp || (Date.now() - parseInt(albumsTimestamp)) > (30 * 24 * 60 * 60 * 1000)) {
        // If pastAlbums or timestamp doesn't exist, or timestamp is older than 1 month, fetch from Firestore
        const pastAlbumsRef = query(collection(db, collectionName));
        const snapshot = await getCountFromServer(pastAlbumsRef);
        let randomNum = Math.floor(Math.random() * snapshot.data().count);

        const randQ = query(collection(db, collectionName));
        const querySnapshot = await getDocs(randQ);

        if (querySnapshot.empty) {
            console.log("No documents found.");
            return null;
        }

        pastAlbums = querySnapshot.docs.map(doc => doc.data());

        // Store pastAlbums and current timestamp in localStorage
        localStorage.setItem(collectionName, JSON.stringify(pastAlbums));
        localStorage.setItem(`${mode}albumsTimestamp`, Date.now());
        return pastAlbums;
    } else {
        pastAlbums = JSON.parse(pastAlbums);
        return pastAlbums;
    }
}